import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChildren,
  ElementRef,
  QueryList,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-repair-cost-row',
  templateUrl: './repair-cost-row.component.html',
  styleUrls: ['./repair-cost-row.component.scss'],
})
export class RepairCostRowComponent implements OnInit, AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter();
  @Output() onDelete: EventEmitter<number> = new EventEmitter();
  @Output() newLineRequested: EventEmitter<boolean> = new EventEmitter();
  @ViewChildren('nameCtrl') nameInputs: QueryList<ElementRef>;

  public details: UntypedFormArray;

  removeCost(indexOfCost: number) {
    (this.details as UntypedFormArray).removeAt(indexOfCost);
  }

  handleSubmit() {
    this.onSubmit.emit(true);
  }

  handleEnterKeyOnCost() {
    this.newLineRequested.emit(true);
  }

  ngOnInit() {
    this.details = this.form.get('details') as UntypedFormArray;
  }

  ngAfterViewInit(): void {
    this.nameInputs.changes.subscribe(() => {
      if (this.nameInputs.length > 1) {
        this.nameInputs.last.nativeElement.focus();
      }
    });
  }
}
