import { Component, ElementRef, OnInit } from '@angular/core';
import { FieldConfig } from '@smartflip/data-models';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  public field!: FieldConfig;
  public group!: UntypedFormGroup;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    // update on Angular life-cycle using setTimeout
    setTimeout(() => {
      if (!this.field?.name) return;

      const fieldName = this.field.name;

      if (this.field?.disabled) {
        this.group.controls[fieldName].disable();
      } else {
        this.group.controls[fieldName].enable();
      }
    }, 100);
  }

  scrollIntoView() {
    this.el.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
