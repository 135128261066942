<div class="RepairCosts">
  <h3 class="RepairCosts__Heading">Current Itemized Repairs</h3>
  <div
    class="RepairCosts__CurrentRepairs d-flex justify-content-between flex-wrap w-100"
  >
    @for (renovation of renovationList; track renovation) {
      <div class="RepairCosts__CurrentRepair flex">
        <h4 class="RepairCosts__Content" [innerHTML]="renovation.name"></h4>
        <span>:&nbsp;</span>
        <label [innerHTML]="renovation.cost | currency"></label>
      </div>
    }
  </div>

  <!-- Open Modal Button -->
  <form
    class="dynamic-form"
    [formGroup]="repairForm"
    (submit)="onSubmit()"
    class="RepairCosts__Form"
  >
    <!-- <mat-form-field [formGroup]="repairForm"> -->
    <!-- <input matInput [formControlName]="'total'" [placeholder]="'Rehab Total'" disabled> -->
    <button
      aria-label="Renovation Details"
      color="primary"
      class="RepairCosts__Button"
      data-test-id="renovationDetailsButton"
      id="renovation-details"
      mat-button
      mat-raised-button
      (click)="showDetails()"
    >
      <mat-icon>list</mat-icon>
      <span>Itemize</span>
    </button>
    <!-- </mat-form-field> -->
  </form>
</div>
