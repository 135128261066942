import { filterControlMetadata } from '@smartflip/data-field-configuration';
import { Comparable } from './comparable';
import { LoanApplication } from './loan-application';
import { FirebaseTimeStamp } from './timestamp';

export type PropertyField = keyof Property;

export type PropertySection =
  | string
  | string[]
  | AcqSection
  | CarrySection
  | RepairDetailSection
  | RepairSection
  | SellSection
  | OtherSection
  | SummarySection
  | GeneralSection
  | MortgageSection
  | Comparable[]
  | LoanApplication;

export class Property {
  id?: string;
  user: string; // Firebase user id for security
  acq: AcqSection;
  carry: CarrySection;
  repair: RepairSection;
  repairdetail: RepairDetailSection;
  selling: SellSection;
  other: OtherSection;
  summary: SummarySection;
  gen: GeneralSection;
  mortgage?: MortgageSection;
  comps?: Comparable[]; // TODO: Build this out to match comp content
  compsFilters?: Record<keyof typeof filterControlMetadata, unknown>;
  loan?: LoanApplication;
  imgUrl?: string;
  images?: string[];

  constructor(user: string) {
    // TODO: Pull defaults from profile
    this.user = user;
    this.gen = {
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      sqft: '',
      lot: '',
      year: '',
    };
    this.mortgage = {
      amount: 0,
      titleCompany: '',
      lender: '',
    };
    this.summary = {
      price: 0,
      tax: 0,
      sell: 0,
      loanpoints: 0.02,
      loanrate: 12,
      loan: '$0',
      loantovalue: 0,
    };
    this.selling = {
      marketing: 0,
      staging: 0,
      commpercent: 0,
      comm: 0,
      closing: 0,
      repair: 0, // Inspection repair, not renovation
      franchiseTransactionFeesPercent: 0,
      ['trans-fees']: 0,
      ['royalty-fees-percent']: 0,
      ['royalty-fees']: 0,
    };
    this.acq = {
      appraisalFee: 0,
      ['base-second-eyes']: 0,
      inspectionFees: 0,
      ['subtotal-base']: 0,
      ['closing-costs-hud-fees']: 0,
      ['loan-points-fees']: 0,
      franchiseFees: 0,
      ['atty-fees-buyer']: 0,
      attorneyFeesSeller: 0,
      ['atty-fees-lender']: 0,
      miscfees: 0,
      ['subtotal-fees']: 0,
      ['other-acquisition-costs']: 0,
      ['subtotal-other']: 0,
      ['total-acquisition-costs']: 0,
    };
    this.repair = {
      total: 20000,
    };
    this.repairdetail = {
      details: [
        {
          cost: 20000,
          name: 'All Repairs',
        },
      ],
    };
    // this.loan = { id: '', closingCompany: '', closingContactName: '' };
    this.carry = {
      duration: 6,
      interest: 0,
      proptax: 0,
      util: 0,
      insurance: 0,
      maintenance: 0,
      fee: 0,
      other: 0,
    };
    this.other = { other1: 0, other2: 0 };
  }
}

export interface SummarySection {
  price: number;
  tax: number;
  sell: number;
  loan: string; // TODO: make this a number. We are currently putting the $ in the value
  loantovalue: number;
  loanpoints: number;
  loanrate: number;
}

export interface AcqSection {
  appraisalFee: number;
  ['base-second-eyes']: number;
  inspectionFees: number;
  ['subtotal-base']: number;
  ['closing-costs-hud-fees']: number;
  ['loan-points-fees']: number;
  franchiseFees: number;
  ['atty-fees-buyer']: number;
  attorneyFeesSeller: number;
  ['atty-fees-lender']: number;
  miscfees: number;
  ['subtotal-fees']: number;
  ['other-acquisition-costs']: number;
  ['subtotal-other']: number;
  ['total-acquisition-costs']: number;
}

export interface CarrySection {
  duration: number;
  interest: number;
  proptax: number;
  util: number;
  insurance: number;
  maintenance: number;
  fee: number;
  other: number;
}

export interface RepairSection {
  total: number;
}

export interface RepairDetailSection {
  details: { cost: number; name: string }[];
}

export interface SellSection {
  marketing: number;
  staging: number;
  commpercent: number;
  comm: number;
  closing: number;
  repair: number;
  franchiseTransactionFeesPercent: number;
  ['trans-fees']: number;
  ['royalty-fees-percent']: number;
  ['royalty-fees']: number;
}

export interface OtherSection {
  other1: number;
  other2: number;
}

// Forms on the "General Tab"
export interface GeneralSection {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  sqft?: string;
  lot?: string;
  year?: string;
  beds?: number;
  baths?: number;
  solddate?: FirebaseTimeStamp;
  purchasedate?: FirebaseTimeStamp;
  geoLocation?: string;
}

export interface OwnerSection {
  first: string;
  last: string;
  address: string;
  mortgageAmount: number;
}

export interface MortgageSection {
  lender: string;
  titleCompany: string;
  amount: number;
}

export interface CategorizedProperties {
  sold: Property[];
  active: Property[];
  potential: Property[];
}

// Used when adding a new property
export interface InitialGeneralData extends GeneralSection {
  images: string[];
  imgUrl: string;
}
