import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '@smartflip/util-import-helper';
import { FeaturesChartsModule } from '@smartflip/charts';
import { InteractiveMapModule } from '@smartflip/interactive-map';
import { FormFieldsModule } from '@smartflip/form-fields';
import { SharedModule } from './../../shared/shared.module';
import { AcquisitionCostsComponent } from './components/acquisition-costs/acquisition-costs.component';
import { CarryingCostsComponent } from './components/carrying-costs/carrying-costs.component';
import { RepairCostsComponent } from './components/repair-costs/repair-costs.component';
import { SellingCostsComponent } from './components/selling-costs/selling-costs.component';
import { OtherCostsComponent } from './components/other-costs/other-costs.component';
import { RepairDetailCostsComponent } from './components/repair-detail-costs/repair-detail-costs.component';
import { ProfitDetailsComponent } from './components/profit-details/profit-details.component';
import { PropertyDetailsComponent } from './property-details.component';
import { BudgetPanelComponent } from './components/budget-panel/budget-panel.component';
import { ComparablesComponent } from './components/comparables/comparables.component';
import { CostDetailsComponent } from './components/cost-details/cost-details.component';
import { GeneralInformationComponent } from './components/general-information/general-information.component';
import { ComparablesCardComponent } from './components/comp-card/comparables-card.component';
import { RepairCostRowComponent } from './components/repair-detail-costs/repair-cost-row/repair-cost-row.component';
import { ComparablesTableComponent } from './components/comparables/comparables-table/comparables-table.component';
import { AddPropertyModalComponent } from './components/add-property-modal/add-property-modal.component';
import { ComparablesFilterActionsComponent } from './components/comparables/comparables-filter-actions/comparables-filter-actions.component';
import { PropertyWorkflowModalComponent } from './components/general-information/property-workflow-modal/property-workflow-modal.component';
import { StatusLegendComponent } from './components/comparables/comparables-status-legend/statusLegend.component';
import { FilterChoicesComponent } from './components/comparables/comparables-filter-choices/filter-choices.component';

@NgModule({
  declarations: [
    AddPropertyModalComponent,
    PropertyDetailsComponent,
    AcquisitionCostsComponent,
    CarryingCostsComponent,
    RepairCostsComponent,
    SellingCostsComponent,
    OtherCostsComponent,
    RepairDetailCostsComponent,
    ProfitDetailsComponent,
    BudgetPanelComponent,
    ComparablesComponent,
    CostDetailsComponent,
    GeneralInformationComponent,
    RepairCostRowComponent,
    ComparablesCardComponent,
    ComparablesTableComponent,
    ComparablesFilterActionsComponent,
    PropertyWorkflowModalComponent,
    FilterChoicesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatTooltipModule,
    FormFieldsModule,
    SharedModule,
    FeaturesChartsModule,
    InteractiveMapModule,
    StatusLegendComponent,
  ],
  exports: [
    PropertyDetailsComponent,
    AcquisitionCostsComponent,
    CarryingCostsComponent,
    RepairCostsComponent,
    SellingCostsComponent,
    OtherCostsComponent,
    RepairDetailCostsComponent,
    ProfitDetailsComponent,
    BudgetPanelComponent,
    ComparablesComponent,
    CostDetailsComponent,
    GeneralInformationComponent,
    ComparablesCardComponent,
    ComparablesTableComponent,
  ],
  providers: [CurrencyPipe, TitleCasePipe],
})
export class PropertyDetailsModule {}
