import { Component, ViewChild, OnInit } from '@angular/core';
import {
  CHART_TYPE,
  ChartComponent,
  DEFAULT_CHART_BACKGROUNDS,
} from '@smartflip/charts';
import { RoiService } from '@smartflip/ui-utils';
import { minDuration, profitLabels } from './profit-details.constants';

@Component({
  selector: 'app-profit-details',
  templateUrl: './profit-details.component.html',
  styleUrls: ['./profit-details.component.scss'],
})
export class ProfitDetailsComponent implements OnInit {
  @ViewChild('piechart', { static: false }) piechart: ChartComponent;
  @ViewChild('linechart', { static: false }) linechart: ChartComponent;

  public profitLabels: string[] = profitLabels;
  public carryingLabels: string[] = [];
  public backgrounds: string[] = [];
  public profitData: number[] = [];
  public carryingData: number[] = [];
  public purchasePrice = 0;
  public sellingPrice = 0;
  public chartTypeBar = CHART_TYPE.BAR;
  public missingDataText =
    'Please enter an estimated purchase price and selling price to view chart';

  getCarryingData() {
    const cData = [];
    const labels = [];
    const colors = [];
    const defaultColors = DEFAULT_CHART_BACKGROUNDS;
    const {
      totalAcquisitionCosts,
      totalSellCosts,
      summary: { price, sell },
      totalCarryCosts,
      totalRepairCosts,
      totalOtherCosts,
    } = this.roi.calculationValues;
    const fixedCosts =
      totalAcquisitionCosts +
      totalSellCosts +
      parseFloat(price.value) +
      totalRepairCosts +
      totalOtherCosts;
    const savedDuration = this.roi.calculationValues.carry['duration'].value;
    const duration = Number(
      savedDuration < minDuration ? minDuration : savedDuration,
    );
    const timeScalePadding = Math.floor(duration / 2) - 1;

    this.purchasePrice = price.value || 0;
    this.sellingPrice = sell.value || 0;

    for (
      let month = timeScalePadding;
      month < duration + timeScalePadding;
      month++
    ) {
      const monthlyCarryCost = totalCarryCosts / duration;
      const monthlyProfit =
        sell.value - (fixedCosts + monthlyCarryCost * month);

      cData.push(monthlyProfit.toFixed(0));
      labels.push(`Month ${month + 1}`);
      colors.push(monthlyProfit > 0 ? defaultColors[0] : defaultColors[2]); // green if profitable, red if not
    }

    this.carryingLabels = labels;
    this.backgrounds = colors;

    if (this.linechart) {
      this.linechart.updateChart(cData);
    }

    return cData;
  }
  // For Pie Chart
  getProfitData(): [number, number, number] {
    const {
      totalAcquisitionCosts,
      totalCarryCosts,
      totalOtherCosts,
      totalRepairCosts,
      totalSellCosts,
      summary: { price, sell },
    } = this.roi.calculationValues;
    const totalAdditionalCosts =
      totalAcquisitionCosts +
      totalCarryCosts +
      totalOtherCosts +
      totalSellCosts +
      totalRepairCosts;
    const profitRaw =
      sell.value - (parseFloat(price.value) + totalAdditionalCosts);
    const profit = profitRaw > 0 ? profitRaw : 0;
    const purchasePrice = price.value;

    if (this.piechart) {
      this.piechart.updateChart([profit, purchasePrice, totalAdditionalCosts]);
    }

    return [profit, purchasePrice, totalAdditionalCosts];
  }

  constructor(private roi: RoiService) {}

  ngOnInit() {
    this.profitData = this.getProfitData();
    this.carryingData = this.getCarryingData();
    this.roi.formsUpdated$.subscribe(() => {
      this.profitData = this.getProfitData();
      this.carryingData = this.getCarryingData();
    });
  }
}
