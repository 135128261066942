import { FieldConfig } from '@smartflip/data-models';

export type CalculationField = keyof Calculation;

export type CalculationSection = number | FieldConfig | Record<string, any>;

export const blankString = '';

export type SummarySectionField = keyof CalculationSummarySection;

export interface CalculationSummarySection {
  price: FieldConfig;
  sell: FieldConfig;
  tax: FieldConfig;
  loanrate: FieldConfig;
  loanpoints: FieldConfig;
  loantovalue: FieldConfig;
  loan: FieldConfig;
}

export class Calculation {
  totalAcquisitionCosts: number;
  totalCarryCosts: number;
  totalRepairCosts: number;
  totalSellCosts: number;
  totalOtherCosts: number;
  allBudgetCosts: number;
  profit: number;
  'loan-points-fees': number;
  summary: CalculationSummarySection;
  gen: { address: { value: string }; zip: { value: string } }; // For calc comp typing
  acq: Record<string, any>;
  carry: Record<string, any>;
  repair: Record<string, any>;
  selling: Record<string, any>;
  repairdetail: Record<string, any>;
  other: Record<string, any>;

  constructor() {
    this.totalAcquisitionCosts = 0;
    this.totalCarryCosts = 0;
    this.totalRepairCosts = 0;
    this.totalSellCosts = 0;
    this.totalOtherCosts = 0;
    this.allBudgetCosts = 0;
    this.profit = 0;
    this['loan-points-fees'] = 0;
    this.summary = {
      price: { type: 'input', value: 0 },
      sell: { type: 'input', value: 0 },
      tax: { type: 'input', value: 0 },
      loan: { type: 'input', value: 0 },
      loanrate: { type: 'input', value: 0 },
      loanpoints: { type: 'input', value: 0 },
      loantovalue: { type: 'input', value: 0 },
    };
    this.gen = { address: { value: blankString }, zip: { value: blankString } }; // For calc comp typing
    this.acq = {};
    this.carry = {};
    this.repair = {};
    this.selling = {};
    this.repairdetail = {};
    this.other = {};
  }
}
