<!--  App Header -->
<ion-header>
  <mat-toolbar
    color="primary"
    class="Home__Header"
    [ngClass]="{ 'Home__Header--Ios': isIos && isPortrait }"
  >
    <div class="Home__Logo" routerLink="/home"></div>
    <!-- <span class="h2" routerLink="/home">Smart Flip</span> -->
    <span class="fill-space"></span>
    <smartflip-menu></smartflip-menu>
  </mat-toolbar>
</ion-header>

<!-- App Content -->
<div
  class="Home__Content"
  slot=""
  [ngClass]="{ 'Home__Content--Ios': isIos && isPortrait }"
  scrollY="true"
>
  <router-outlet></router-outlet>
</div>
