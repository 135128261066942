import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toThousandsCurrency',
})
export class ToThousandsCurrencyPipe implements PipeTransform {
  constructor(private cPipe: CurrencyPipe) {}

  transform(value: number): string {
    if (!value) {
      return '0';
    }

    const dividedValue =
      typeof value === 'number' && value > 1000 ? value / 1000 : value;
    const formattedValue =
      this.cPipe.transform(dividedValue, 'USD', 'symbol', '1.0-0') || '0';

    // add the 'k' only if original value was >1000
    return `${formattedValue}${value > 1000 ? 'k' : ''}`;
  }
}
