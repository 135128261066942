<div class="RepairCostDetail">
  <header class="RepairCostDetail__Header">
    <h3>Repairs Total: {{ total | currency }}</h3>
  </header>

  <main class="RepairCostDetail__Container">
    <app-repair-cost-row
      class="RepairCostDetail__RepairRows"
      [form]="repairDetailForm"
      (newLineRequested)="addCost()"
    ></app-repair-cost-row>
    <!-- Add Another Repair -->
    <div class="row p-2 w-100">
      <button mat-button mat-raised-button class="accent" (click)="addCost()">
        <mat-icon>add</mat-icon>
        <span>Add </span>
        @if (repairDetailForm.get('details')?.value.length > 0) {
          <span [innerHTML]="'another '"> </span>
        }
        <span>cost</span>
      </button>
    </div>
  </main>

  <div class="RepairCostDetail__ButtonWrapper">
    <div class="d-flex flex-wrap">
      <!-- Footer -->
      <div class="row p d-flex w-100 justify-content-between">
        <div class="p-2">
          <button
            class="RepairCostDetail__Button"
            mat-button
            mat-raised-button
            [innerHTML]="'Cancel'"
            (click)="close()"
          ></button>
        </div>
        <div class="p-2">
          <button
            class="RepairCostDetail__Button accent"
            [ngClass]="{
              'RepairCostDetail__Button--Disabled': repairDetailForm.invalid
            }"
            [disabled]="!repairDetailForm.valid"
            mat-button
            mat-raised-button
            [innerHTML]="'Save'"
            (click)="save()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
