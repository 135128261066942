import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EmailSignupOptions } from '../login.constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'smartflip-login-email-form',
  templateUrl: './login-email-form.component.html',
  styleUrls: ['./login-email-form.component.scss'],
})
export class LoginEmailFormComponent implements OnInit {
  @Input() loginForm!: UntypedFormGroup;
  @Input() newAccount: boolean = false; // User is creating an account
  @Input() forgotPassword: boolean = false; // User has forgotten password
  @Input() showPasswordResetConfirmation: boolean = false;
  @Input() resettingPassword: boolean = false;
  @Input() error: string | null = null;
  @Output() newAccountChange: EventEmitter<boolean> = new EventEmitter();
  @Output() forgotPasswordChange: EventEmitter<boolean> = new EventEmitter();
  @Output() loginButtonClicked: EventEmitter<null> = new EventEmitter();
  @Output() createAccountClicked: EventEmitter<EmailSignupOptions> =
    new EventEmitter();
  @Output() forgotPasswordClicked: EventEmitter<string> = new EventEmitter();

  public loginButtonText$: BehaviorSubject<string> = new BehaviorSubject(
    'Log In',
  );
  public forgotPasswordText$: BehaviorSubject<string> = new BehaviorSubject(
    'Forgot your password?',
  );
  public toggleCreateText = '';

  public createAccount() {
    const { email, password } = this.loginForm.value;

    this.createAccountClicked.emit({ email, password });
  }

  public handleEmailLogin() {
    this.loginButtonClicked.emit();
  }

  public handleForgotPassword() {
    const { email } = this.loginForm.value;

    this.forgotPasswordClicked.emit(email);
  }

  public handleSubmit(event: SubmitEvent) {
    event?.preventDefault();
    console.log('submitted', this.loginForm.value);
  }

  public toggleCreateAccount() {
    this.newAccount = !this.newAccount;
    this.setCreateButtonText();
    this.loginButtonText$.next(this.getLoginButtonText());
    this.newAccountChange.emit(this.newAccount);
  }

  public toggleForgotPassword() {
    this.forgotPassword = !this.forgotPassword;

    const passwordText = this.forgotPassword
      ? 'Back to Sign in'
      : 'Forgot your password?';

    this.loginButtonText$.next(this.getLoginButtonText());
    this.forgotPasswordText$.next(passwordText);
    this.forgotPasswordChange.emit(this.forgotPassword);
  }

  private setCreateButtonText() {
    this.toggleCreateText = this.newAccount
      ? 'Already have an account? Click here to log in.'
      : 'No account yet? Create a free one!';
  }

  private getLoginButtonText(): string {
    return this.newAccount
      ? 'Create Account'
      : this.forgotPassword
        ? 'Reset Password'
        : 'Log In';
  }

  ngOnInit(): void {
    this.setCreateButtonText();
  }
}
