export const propertyHighlights = {
  totalBorrowed: {
    title: 'open loans',
    value: 0,
    color: 'primary',
  },
  totalRenovation: {
    title: 'active renovations',
    value: 0,
    color: 'primary',
  },
  totalProfits: {
    title: 'profit this year',
    value: 0,
    color: 'primary',
  },
};
