@if (details.controls.length > 0) {
  <form class="dynamic-form" [formGroup]="form" (submit)="handleSubmit()">
    <ng-container formArrayName="details">
      @for (ctrl of details.controls; track ctrl; let i = $index) {
        <div class="row d-flex justify-content-between">
          <ng-container [formGroupName]="i">
            <div class="p-2">
              <mat-form-field [id]="'name-' + i" class="demo-full-width">
                <input
                  matInput
                  formControlName="name"
                  placeholder="Name"
                  type="text"
                  #nameCtrl
                  required="true"
                  min="3"
                />
              </mat-form-field>
            </div>
            <div class="p-2">
              <mat-form-field [id]="'cost-' + i" class="demo-full-width">
                <input
                  matInput
                  formControlName="cost"
                  placeholder="Cost"
                  type="number"
                  (keydown.enter)="handleEnterKeyOnCost()"
                />
              </mat-form-field>
            </div>
            <div class="p-2">
              <button
                class="RepairCostDetailsRow__Icon--delete"
                mat-mini-fab
                color="warn"
                (click)="removeCost(i)"
                type="button"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
      }
    </ng-container>
  </form>
} @else {
  <p class="warning">Please add a cost to using the Add cost button</p>
}
