import { FirebaseTimeStamp } from '@smartflip/data-models';

export const timestampToDate = (
  dateValue: FirebaseTimeStamp | Date | string | undefined
): Date | null => {
  if (!dateValue) return null;

  if (typeof dateValue === 'string') {
    return new Date(dateValue);
  }

  if ((dateValue as FirebaseTimeStamp).seconds) {
    return new Date((dateValue as FirebaseTimeStamp).seconds);
  }

  return dateValue as Date;
};
