import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { confirmPasswordValidator } from './confirm-password.validator';

export interface FederatedLoginButtonConfiguration {
  icon: string;
  buttonText: string;
  provider: Providers;
}

export type Providers = 'email' | 'google' | 'facebook' | 'apple';

export interface EmailSignupOptions {
  email: string;
  password: string;
}

export const oneSecond = 1000;

export const forgotPasswordForm = new UntypedFormGroup({
  email: new UntypedFormControl('', [Validators.required]),
});

export const existingAccountLoginForm = new UntypedFormGroup({
  email: new UntypedFormControl('', [Validators.required]),
  password: new UntypedFormControl('', [Validators.required]),
});
export const newAccountLoginForm = new UntypedFormGroup(
  {
    email: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    confirmPassword: new UntypedFormControl('', [Validators.required]),
  },
  confirmPasswordValidator,
);
export const federatedLoginButtonConfigurations: FederatedLoginButtonConfiguration[] =
  [
    {
      icon: 'logo-google',
      buttonText: 'Continue with Google',
      provider: 'google',
    },
    {
      icon: 'logo-facebook',
      buttonText: 'Continue with Facebook',
      provider: 'facebook',
    },
    {
      icon: 'logo-apple',
      buttonText: 'Continue with Apple',
      provider: 'apple',
    },
  ];
