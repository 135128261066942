<div class="Alert">
  <p>{{ message }}</p>
  <div class="Alert__ButtonRow">
    <button
      mat-raised-button
      class="Alert__Button"
      (click)="cancel()"
      color="primary"
    >
      Cancel
    </button>
    <button mat-raised-button class="Alert__Button" (click)="continue()">
      Continue
    </button>
  </div>
</div>
