import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@smartflip/authentication';
import { FirebaseError } from '@angular/fire/app';
import { PRIVACY_POLICY_URL } from '@smartflip/data-constants';
import {
  federatedLoginButtonConfigurations,
  existingAccountLoginForm,
  oneSecond,
  Providers,
  newAccountLoginForm,
  forgotPasswordForm,
} from './login.constants';

@Component({
  selector: 'smartflip-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public federatedLoginButtons = federatedLoginButtonConfigurations;
  public privacyPolicyUrl = PRIVACY_POLICY_URL;
  public showPasswordResetConfirmation: boolean = false;
  public resettingPassword: boolean = false;
  public usingEmailLogin: boolean = false;
  public forgotPassword: boolean = false;
  public newAccount: boolean = false;
  public error: string | null = null;
  public loginForm: UntypedFormGroup = existingAccountLoginForm;

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
    if (this.authService.authenticated) {
      this.router.navigate(['/home']);
    }
  }

  public emailLogin() {
    const { email, password } = this.loginForm.value;
    let emailPromise;
    this.error = null;

    if (this.newAccount) {
      emailPromise = this.authService.createEmailUser(email, password);
    } else if (this.forgotPassword) {
      this.resettingPassword = true;
      emailPromise = this.authService.resetPassword(email);
    } else {
      emailPromise = this.authService.login('email', email, password);
    }

    emailPromise
      .then((user: unknown) => {
        if (this.resettingPassword) {
          this.showPasswordResetConfirmation = true; // We get no feedback from this request so just show the confirmation regardless
        }
        if (user) {
          this.router.navigate(['/home']);
        } else {
          this.router.navigate(['/login']);
        }
      })
      .catch((error: FirebaseError) => {
        this.error = error?.code;
      })
      .finally(() => {
        setTimeout(() => (this.resettingPassword = false), oneSecond); // clear the loader after ensuring users can see it
      });
  }

  // Social Media logins, Facebook, Google, Apple, etc.
  public federatedLogin(provider: Providers) {
    this.authService
      .login(provider)
      .then(() => {
        this.router.navigate(['/home']);
      })
      .catch((error: FirebaseError) => {
        if (
          error.message.includes(
            'Error: An account already exists with the same email',
          )
        ) {
          console.log(error.message);
          // Prompt the user
        }
        this.router.navigate(['/login']);
      });
  }

  handleNewAccountChange() {
    this.loginForm = this.newAccount
      ? newAccountLoginForm
      : existingAccountLoginForm;
  }

  handleForgotPasswordChange() {
    this.loginForm = this.forgotPassword
      ? forgotPasswordForm
      : existingAccountLoginForm;
  }
}
