<div class="Login">
  <ion-header>
    <mat-toolbar color="primary" class="Login__Header">
      <div class="Login__Logo"></div>
      <span class="fill-space"></span>
    </mat-toolbar>
  </ion-header>

  <div class="Login__Content container-fluid">
    <div class="Login__Welcome Login__Row row">
      <div class="col">
        <mat-card>
          <h2>Please log in to continue.</h2>
          <p>
            In order to save your changes and keep your data private and safe,
            log in to make sure your work is here when you come back.
          </p>
          <p>
            We will never have access to your password or any other confidential
            information.
          </p>
        </mat-card>
      </div>
    </div>

    <div class="row Login__Row mt-1">
      <div class="col">
        <mat-card class="py-4">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <smartflip-login-email-form
                  [loginForm]="loginForm"
                  [resettingPassword]="resettingPassword"
                  [showPasswordResetConfirmation]="
                    showPasswordResetConfirmation
                  "
                  [error]="error"
                  (loginButtonClicked)="emailLogin()"
                  [(forgotPassword)]="forgotPassword"
                  [(newAccount)]="newAccount"
                  (newAccountChange)="handleNewAccountChange()"
                  (forgotPasswordChange)="handleForgotPasswordChange()"
                ></smartflip-login-email-form>
              </div>
            </div>

            @if (!newAccount && !forgotPassword) {
              <div class="row">
                <div class="col text-center">
                  <h6>Or sign in with your provider of choice</h6>
                </div>
              </div>

              @for (loginButton of federatedLoginButtons; track loginButton) {
                <div class="row">
                  <div class="col text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      class="Login__Button m-2"
                      (click)="federatedLogin(loginButton.provider)"
                    >
                      <i class="fab" [ngClass]="loginButton.icon"></i>
                      <ion-icon [name]="loginButton.icon"></ion-icon>
                      <span [innerHTML]="loginButton.buttonText"></span>
                    </button>
                  </div>
                </div>
              }
            }
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <footer class="Login__Footer">
    <mat-toolbar color="primary">
      <p>Copyright &#64;2024 The Smart Flip, LLC.</p>
      <p>
        <a
          class="Login__FooterLink"
          [href]="privacyPolicyUrl"
          [innerHTML]="'Privacy Policy'"
        ></a>
      </p>
    </mat-toolbar>
  </footer>
</div>
