import { UntypedFormControl, Validators } from '@angular/forms';
import {
  GoogleAddressObject,
  addressComponentsToObject,
} from '@smartflip/utils-data';
import { AddressFormBaseValue } from './add-property-modal.constants';

export const createNewFormControl = (
  formControlName: string,
  formControlValue = '',
) => {
  const notRequiredFields = [];
  const validator = notRequiredFields.includes(formControlName)
    ? null
    : Validators.required;

  return new UntypedFormControl(formControlValue, validator);
};

export const processGoogleAddress = (addressResult): AddressFormBaseValue => {
  const addressObject: GoogleAddressObject = addressComponentsToObject(
    addressResult.address_components,
  );
  const {
    street_number,
    route,
    locality,
    administrative_area_level_1,
    postal_code,
  } = addressObject;
  const formValue = {
    address: `${street_number} ${route}`,
    city: locality,
    state: administrative_area_level_1,
    zip: postal_code,
  };

  return formValue;
};
