<!-- Button Row -->
<div class="ComparablesFilterActions container-fluid">
  <div class="row d-flex justify-content-between">
    <div class="p-2 col-12 col-sm-6 d-flex justify-content-start">
      <button
        mat-button
        mat-raised-button
        class="ComparablesFilterActions__Button"
        color="secondary"
        data-test-id="generateArv"
        id="generate-arv"
        [innerHTML]="arvButtonText"
        (click)="generateARV()"
        [disabled]="selectedComps.length === 0"
      ></button>
    </div>
    <!-- Second Row when Small -->
    <div
      class="p-2 col-12 col-sm-6 d-flex align-items-center justify-content-start justify-content-sm-end"
    >
      <button
        data-test-id="pullComparablesButton"
        id="pull-comparables"
        mat-button
        mat-raised-button
        (click)="pullComparables()"
        color="primary"
        [innerHTML]="pullCompsButtonText"
      ></button>
    </div>
  </div>

  <div class="row w-100">
    <div class="pl-2 pr-2 col-12">
      <p for="arv">
        <b>Current ARV:</b>
        <span>&nbsp;&nbsp;</span>
        <span
          data-test-id="currentArv"
          id="current-arv"
          [innerHTML]="ARV$ | async | currency"
        ></span>
      </p>
    </div>
  </div>

  <!-- A user's existing filter choice -->
  <smartflip-filter-choices
    [filterOptions]="filterOptions"
  ></smartflip-filter-choices>
</div>
