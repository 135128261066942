import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { startWith } from 'rxjs';
import { FieldConfig } from '@smartflip/data-models';
import { RoiService } from '@smartflip/ui-utils';
import { summaryFields } from '@smartflip/data-field-configuration';

@Component({
  selector: 'app-cost-details',
  templateUrl: './cost-details.component.html',
  styleUrls: ['./cost-details.component.scss'],
})
export class CostDetailsComponent implements OnInit {
  @Input() propertyID: string;

  public homeFieldList: FieldConfig[] = [...summaryFields];
  public summaryForm: UntypedFormGroup;
  public profitMessage = 'enter sell price to calculate profit';

  constructor(public roi: RoiService) {}

  async init(): Promise<void> {
    const summaryForm = this.roi.formGroups['gen'];

    this.summaryForm = this.roi.formGroups.summary;

    summaryForm.valueChanges
      .pipe(startWith(null))
      .subscribe(this.updateEstimatedStatus.bind(this));
  }

  ngOnInit() {
    this.init();
  }

  private updateEstimatedStatus() {
    const summaryForm = this.roi.formGroups['gen'];
    const disableAbleFields = ['price', 'sell'];

    this.homeFieldList = this.homeFieldList.map((field) => {
      const fieldIsEstimated =
        (field.name === 'price' && !summaryForm.value?.purchasedate) ||
        (field.name === 'sell' && !summaryForm.value?.solddate);

      return {
        ...field,
        label: fieldIsEstimated ? field.label + ' (est)' : field.label,
        disabled: !fieldIsEstimated && disableAbleFields.includes(field.name),
      };
    });
  }
}
