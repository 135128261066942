<mat-accordion class="BudgetPanel" color="primary">
  @for (subBudget of budgets; track subBudget) {
    <mat-expansion-panel [id]="subBudget.abbr + '-panel'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ subBudget.title }}
          {{ roi.calculationValues[subBudget.name] | currency }}
          <span class="BudgetPanel__RightSide" *ngIf="subBudget.abbr === 'rep'">
            <mat-checkbox
              (change)="toggleRepair()"
              [checked]="includeRepairInLoan"
            ></mat-checkbox>
            <label>&nbsp;&nbsp;Include in Loan</label>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      @switch (subBudget.abbr) {
        @case ('acq') {
          <app-acquisition-costs></app-acquisition-costs>
        }
        @case ('cry') {
          <app-carrying-costs></app-carrying-costs>
        }
        @case ('rep') {
          <app-repair-costs></app-repair-costs>
        }
        @case ('sel') {
          <app-selling-costs></app-selling-costs>
        }
        @case ('oth') {
          <app-other-costs></app-other-costs>
        }
      }
    </mat-expansion-panel>
  }
</mat-accordion>
