import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { SwiperPhoto } from '@smartflip/data-models';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';

@Component({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    PhotoCarouselComponent,
  ],
  selector: 'smartflip-photo-carousel-modal',
  templateUrl: './photo-carousel-modal.component.html',
  styleUrls: ['./photo-carousel-modal.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class PhotoCarouselModalComponent implements OnInit {
  public photos: SwiperPhoto[] = [];
  private activeSlideIndex: number = 0;

  constructor(
    private dialogRef: MatDialogRef<PhotoCarouselModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { photos: SwiperPhoto[] },
  ) {}

  public closeModal(selectedImgUrl?: string) {
    this.dialogRef.close(selectedImgUrl);
  }

  public selectForThumbnail() {
    this.closeModal(this.photos[this.activeSlideIndex].url);
  }

  public updateActiveIndex(sliderIndex: number) {
    this.activeSlideIndex = sliderIndex;
  }

  ngOnInit(): void {
    this.photos = this.data.photos;
  }
}
