<section
  class="FinancialAggregates d-flex justify-content-center flex-wrap"
  role="complimentary"
>
  <h3 class="FinancialAggregates__Header">Highlights</h3>
  <div class="d-flex">
    @for (badge of badges; track badge) {
      <div class="FinancialAggregates__Badge">
        <span
          class="FinancialAggregates__Dollars"
          [innerHTML]="badge.value | async | toThousandsCurrency"
        ></span>
        <span [innerHTML]="badge.title"></span>
      </div>
    }
  </div>
</section>
