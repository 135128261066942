import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { Router } from '@angular/router';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideFirestore, initializeFirestore } from '@angular/fire/firestore';
import { provideAnalytics, initializeAnalytics } from '@angular/fire/analytics';
import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import * as Sentry from '@sentry/angular';
/* internal dependencies */
import { MaterialModule } from '@smartflip/util-import-helper';
import { FeaturesAccountDeletionConfirmationModalModule } from '@smartflip/feat-account-deletion-modal';
import { StripePaymentModule, StripePaymentService } from '@smartflip/stripe';
import { FeaturesLoadingIndicatorModule } from '@smartflip/loading-indicator';
import { DataServicesDatafinitiModule } from '@smartflip/datafiniti';
import { ServicesHelperUtilsModule } from '@smartflip/helper-utils';
import { CustomCurrencyPipe, ToThousandsCurrencyPipe } from '@smartflip/pipes';
import { MenuComponent } from '@smart-flip/shared-components';
import {
  CheckBoxComponent,
  RadioButtonComponent,
} from '@smartflip/form-fields';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { FinancialAggregatesComponent } from './views/home-list/financial-aggregates/financial-aggregates.component';

import { LenderFormComponent } from './views/lender/lender-form/lender-form.component';
import { HomeListComponent } from './views/home-list/home-list.component';
import { PropertyDetailsModule } from './views/property-details/property-details.module';
import { SharedModule } from './shared/shared.module';
import { HomeListCardComponent } from './views/home-list/home-list-card/home-list-card.component';
import { environment } from '../environments/environment';

@NgModule({
  providers: [
    provideFirebaseApp(() =>
      initializeApp(environment.firebase, 'The Smart Flip'),
    ),
    provideFirestore(() =>
      initializeFirestore(getApp(), { ignoreUndefinedProperties: true }),
    ),
    provideAnalytics(() => initializeAnalytics(getApp())),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()), // only needed for storage features
    CurrencyPipe,
    TitleCasePipe,
    ToThousandsCurrencyPipe,
    ScreenOrientation,
    StripePaymentService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    PropertyDetailsModule,
    SharedModule,
    DataServicesDatafinitiModule.forRoot(environment),
    ServicesHelperUtilsModule.forRoot(environment),
    StripePaymentModule.forRoot(environment),
    GoogleMapsModule,
    IonicModule.forRoot(),
    FeaturesLoadingIndicatorModule,
    FeaturesAccountDeletionConfirmationModalModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    CheckBoxComponent,
    RadioButtonComponent,
    CustomCurrencyPipe,
    FinancialAggregatesComponent,
    LenderFormComponent,
    HomeListComponent,
    MenuComponent,
    HomeListCardComponent,
    ToThousandsCurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
