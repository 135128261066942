import { Injectable } from '@angular/core';
import {
  Borrower,
  LoanApplication,
  LoanComp,
  Property,
} from '@smartflip/data-models';
import { timestampToDate } from '@smartflip/utils-data';

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  constructor() {}

  getLoanById(loanId: string) {}

  public async createInitialLoanValues(
    currentProperty: Property,
    borrowerInformation: Borrower,
  ) {
    const today = new Date();
    const { comps, gen, summary, repair } = currentProperty;
    const loanComps: LoanComp[] =
      comps?.map((comp) => ({
        address: `${comp.street}, ${comp.city}, ${comp.state}, ${comp.zipcode}`,
        saleDate: new Date(comp.lastSoldDate),
        salePrice: comp.lastSoldPrice,
      })) || [];
    const coeDate = timestampToDate(gen.purchasedate) || new Date();
    const startingLoanData: LoanApplication = {
      id: (+new Date()).toString(36),
      borrower: borrowerInformation,
      subjectProperty: {
        street1: gen.address,
        street2: '',
        city: gen.city,
        state: gen.state,
        zip: gen.zip,
        propertyType: 'condo',
        noOfUnits: 1,
        beds: 3,
        baths: 2,
        yearBuilt: Number(gen.year),
        size: Number(gen.sqft),
        lotSize: 0.2,
        vacant: true,
        noVacantDescription: '',
      },
      loanInformation: {
        purchasePrice: summary.price,
        renovationBudget: repair.total,
        loanAmount: summary.loan,
        loanTerm: 360,
        coeDate,
        closing: {
          company: '',
          contactEmail: '',
          contactName: '',
          contactPhone: '',
        },
        insurance: {
          company: '',
          contactEmail: '',
          contactName: '',
          contactPhone: '',
        },
      },
      comps: loanComps,
      renovationDetails: {
        timelineInWeeks: 4,
        estCompletionDate: new Date(today.setDate(4 * 7 + today.getDate())),
        linkToPhotos: '',
        permitsRequired: false,
        permitTimeInWeeks: 2,
        addingSqft: false,
        structuralWork: false,
        structuralDescription: '',
        foundationWork: false,
        foundationDescription: '',
        disasterRepairs: false,
        disasterDescription: '',
      },
      supportingDocuments: {},
    };

    return startingLoanData;
  }
}
