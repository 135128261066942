import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldConfig } from '@smartflip/data-models';
import { createNewFormControl } from '../../add-property-modal/add-property-modal.helper';
import { createWorkflowFieldList } from './property-workflow-modal.helper';
import {
  buyingFields,
  buyingText,
  sellingFields,
  sellingText,
} from './property-workflow-modal.constants';

@Component({
  selector: 'smartflip-property-workflow-modal',
  templateUrl: './property-workflow-modal.component.html',
  styleUrls: ['./property-workflow-modal.component.scss'],
})
export class PropertyWorkflowModalComponent implements OnInit {
  public modalTitle: string;
  public updateForm: UntypedFormGroup;
  public fieldList: FieldConfig[];
  public objectKeys = Object.keys;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { isBuying: boolean },
    private dialogRef: MatDialogRef<PropertyWorkflowModalComponent>,
    private formBuilder: FormBuilder,
  ) {}

  cancel() {
    this.dialogRef.close();
  }

  save() {
    const { solddate, purchasedate, sell, price } = this.updateForm.value;

    this.dialogRef.close({
      newDate: solddate || purchasedate,
      newDollarAmount: sell || price,
      updatePurchaseDate: this.data.isBuying,
    });
  }

  ngOnInit() {
    this.fieldList = createWorkflowFieldList(
      this.data.isBuying ? buyingFields : sellingFields,
    );
    const formShape = this.fieldList.reduce(
      (formObj, frmFld) => ({
        ...formObj,
        [frmFld.name]: createNewFormControl(
          frmFld.name,
          this.data[frmFld.name],
        ),
      }),
      {},
    );

    this.updateForm = this.formBuilder.group(formShape);
    this.modalTitle = this.data.isBuying ? buyingText : sellingText;
  }
}
