import { NgModule } from '@angular/core';
import {
  RouterModule,
  Router,
  ActivationEnd,
  NavigationEnd,
} from '@angular/router';
import { filter, map, buffer, distinctUntilChanged } from 'rxjs';
import { FirebaseAnalyticsService } from '@smartflip/data-firebase';
import { routes } from './routes';

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
    const activationEnd$ = this.router.events.pipe(
      filter<ActivationEnd>((event) => event instanceof ActivationEnd)
    );
    const navigationEnd$ = this.router.events.pipe(
      filter<NavigationEnd>((event) => event instanceof NavigationEnd)
    );

    activationEnd$
      .pipe(
        map((event) => event.snapshot.routeConfig.path),
        buffer(navigationEnd$),
        map((paths) => paths.reverse().join('/')),
        distinctUntilChanged()
      )
      .subscribe((path) => {
        this.firebaseAnalyticsService.logEvent({
          name: 'screen_view',
          params: { view: path },
        });
      });
  }
}
