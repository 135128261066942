import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadFormComponent } from '../property-image/upload-form/upload-form.component';

@Component({
  selector: 'smartflip-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  message: string = '';

  continue() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    private dialogRef: MatDialogRef<UploadFormComponent>,
  ) {}

  ngOnInit() {
    const DEFAULT_MESSAGE = 'Would you like to continue?';
    this.message = this.data.message || DEFAULT_MESSAGE;
  }
}
