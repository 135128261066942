import { CategorizedProperties, Property } from '@smartflip/data-models';

export const createPropertyList = (
  properties: Property[],
  showAll?: boolean,
): CategorizedProperties => {
  // TODO: Filter properties in Firebase
  // removes all but one POTENTIAL property when not subscribed
  const subscribedToProperties = showAll
    ? properties
    : properties
      .filter(
        (property) => !property.gen.solddate && !property.gen.purchasedate,
      )
      .slice(0, 1);
  const newProps = {
    sold: subscribedToProperties.filter((property) => property.gen.solddate),
    active: subscribedToProperties.filter(
      (property) => property.gen.purchasedate && !property.gen.solddate,
    ),
    potential: subscribedToProperties.filter(
      (property) => !property.gen.solddate && !property.gen.purchasedate,
    ),
  };

  return newProps;
};
