<!-- General Home Costing Information -->
<mat-card>
  <mat-expansion-panel id="property-details-panel">
    <mat-expansion-panel-header
      ><h5>Property Details</h5></mat-expansion-panel-header
    >
    <form class="dynamic-form" [formGroup]="summaryForm">
      @for (field of homeFieldList; track field.name) {
        <ng-container dynamicField [field]="field" [group]="summaryForm">
        </ng-container>
      }
    </form>
  </mat-expansion-panel>
</mat-card>

<mat-card>
  <h2>Budget</h2>
  <div class="row">
    <div class="col-6 d-flex justify-content-between flex-column">
      <mat-label>Total Budgeted Costs</mat-label>
      <h4 [innerHTML]="roi.calculationValues['allBudgetCosts'] | currency"></h4>
    </div>
    <div class="col-6 d-flex justify-content-between flex-column">
      <mat-label>Net Profit</mat-label>
      <h4
        [ngClass]="roi.calculationValues['profit'] > 0 ? 'green' : 'red'"
        [innerHTML]="roi.calculationValues['profit'] | currency"
      ></h4>
    </div>
  </div>
  <app-budget-panel></app-budget-panel>
</mat-card>
