import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  AlertDialogComponent,
  PropertyImageComponent,
  UploadFormComponent,
} from '@smart-flip/shared-components';
import { MaterialModule } from '@smartflip/util-import-helper';
import { FeaturesPhotoCarouselModule } from '@smartflip/carousel';

@NgModule({
  declarations: [UploadFormComponent, AlertDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FeaturesPhotoCarouselModule,
    PropertyImageComponent,
  ],
  exports: [PropertyImageComponent, UploadFormComponent, AlertDialogComponent],
})
export class SharedModule {}
