<div class="GeneralInformation">
  <mat-card>
    <div class="GeneralInformation__StatusRow container">
      <div class="row">
        <div class="col">
          <mat-chip
            selected
            color="primary"
            class="GeneralInformation__Status"
            [innerHTML]="propertyStatus | titlecase"
          ></mat-chip>
          <span
            class="GeneralInformation__StatusDate"
            [innerHTML]="propertyStatusDate"
          ></span>
          @if (propertyStatusDate) {
            <button mat-button color="primary" (click)="workflowProgress(true)">
              <mat-icon>edit</mat-icon>
            </button>
          }
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-12 mb-2">
          <smartflip-property-image
            class="GeneralInformation__Image"
            [imgUrl]="imgUrl"
            [editable]="true"
            [initialSlideIndex]="selectedPhotoIndex"
            imgClass="GeneralInformation__ImageDeep"
            (thumbnailChanged)="updateThumbnailImage($event)"
            [propertyId]="propertyID"
            [propertyPhotos]="images"
          ></smartflip-property-image>
        </div>
        <div
          class="GeneralInformation__ButtonWrapper col-12 col-md-5 flex-wrap flex"
        >
          @if (loanFeatureEnabled) {
            <button
              class="GeneralInformation__Button"
              mat-raised-button
              color="primary"
              (click)="viewLoanApplication()"
            >
              Fund this Flip
              <mat-icon>monetization_on</mat-icon>
            </button>
          }
          @if (propertyStatus !== 'Sold') {
            <button
              class="GeneralInformation__Button"
              color="primary"
              data-test-id="workflowProgressButton"
              id="workflow-progress"
              mat-raised-button
              (click)="workflowProgress()"
              [disabled]="pullingAPIDataNotAllowed()"
            >
              <span [innerHTML]="workflowButtonText"></span>&nbsp;
              <mat-icon [innerHTML]="'paid-outline'"></mat-icon>
              <span
                *ngIf="pullingAPIDataNotAllowed()"
                class="GeneralInformation__Warning"
                [innerHTML]="'Full address is required to continue.'"
              ></span>
            </button>
          }
          <button
            class="GeneralInformation__Button"
            mat-raised-button
            (click)="refreshGeneralData()"
            [disabled]="!isDataRefreshAllowed()"
          >
            <span [innerHTML]="'Retrieve Public Data'"></span>&nbsp;
            <mat-icon>refresh</mat-icon>
            <span
              *ngIf="pullingAPIDataNotAllowed()"
              class="GeneralInformation__Warning"
              >Full address is required to continue.</span
            >
            @if (!isDataRefreshAllowed() && !pullingAPIDataNotAllowed()) {
              <span class="GeneralInformation__Warning--Subscribe"
                >Upgrade to the <a href="/home/subscribe">Pro Tier</a> now for
                additional refreshes!</span
              >
            }
          </button>
          <button
            class="GeneralInformation__Button"
            color="warn"
            data-test-id="deletePropertyButton"
            id="delete-property"
            mat-raised-button
            (click)="deleteProperty()"
          >
            Delete this Property
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card>

  <main id="general-information-cards">
    <!-- General Card -->
    <mat-card>
      <header class="GeneralInformation__Header">
        <h4>General Property Info</h4>
      </header>

      <form
        *ngIf="!loading"
        class="GeneralInformation__Form"
        [formGroup]="generalForm"
        (submit)="onSubmit($event)"
      >
        <ng-container
          *ngFor="let field of objectKeys(fieldList); let i = index"
          dynamicField
          [field]="fieldList[field]"
          [group]="generalForm"
        >
          <div *ngIf="i === 5" class="break"></div>
        </ng-container>
      </form>
    </mat-card>

    <!-- Owner Info -->
    <mat-card>
      <header class="GeneralInformation__Header">
        <h4>Owner Information</h4>
      </header>
      <form
        *ngIf="!loading"
        class="GeneralInformation__Form"
        [formGroup]="ownerForm"
      >
        <ng-container
          *ngFor="let field of objectKeys(ownerFieldList); let i = index"
          dynamicField
          [field]="ownerFieldList[field]"
          [group]="ownerForm"
          [className]="i === 2 ? 'flex-30' : ''"
        >
        </ng-container>
      </form>
    </mat-card>

    <mat-card>
      <header class="GeneralInformation__Header">
        <h4>Mortgage Information</h4>
      </header>
      <!-- Mortgage Info -->
      <form
        *ngIf="!loading"
        class="GeneralInformation__Form"
        [formGroup]="mortgageForm"
      >
        <ng-container
          *ngFor="let field of objectKeys(mortgageFieldList); let i = index"
          dynamicField
          [field]="mortgageFieldList[field]"
          [group]="mortgageForm"
          [className]="i === 2 ? 'flex-30' : ''"
        >
        </ng-container>
      </form>
    </mat-card>
  </main>
</div>
