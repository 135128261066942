<div class="row">
  <div class="col d-flex justify-content-center">
    <div class="EmailLoginForm__Wrapper d-flex">
      <form
        [formGroup]="loginForm"
        class="d-flex flex-wrap"
        (submit)="handleSubmit($event)"
      >
        @if (forgotPassword) {
          <h4 class="EmailLoginForm__Header">
            Enter the email associated with your account to continue
          </h4>
        }
        <mat-form-field>
          <mat-label for="email">Email:</mat-label>
          <input
            matInput
            id="email"
            name="email"
            formControlName="email"
            required="true"
          />
        </mat-form-field>
        @if (!forgotPassword) {
          <mat-form-field>
            <mat-label for="password">Password:</mat-label>
            <input
              matInput
              type="password"
              name="password"
              id="password"
              formControlName="password"
              required="true"
              [disabled]="forgotPassword"
            />
          </mat-form-field>
        }
        @if (!newAccount) {
          <button
            class="EmailLoginForm__StateButton EmailLoginForm__ForgotPasswordButton"
            mat-button
            type="button"
            [innerHTML]="forgotPasswordText$ | async"
            (click)="toggleForgotPassword()"
          ></button>
        }
        @if (newAccount) {
          <mat-form-field>
            <mat-label for="confirm-password">Confirm Password:</mat-label>
            <input
              matInput
              type="password"
              name="confirm-password"
              formControlName="confirmPassword"
              required="true"
              [disabled]="!newAccount"
            />
          </mat-form-field>
          @if (loginForm.errors?.['PasswordNoMatch']) {
            <p class="warning">Passwords must match</p>
          }
        }
        @if (!forgotPassword) {
          <button
            class="EmailLoginForm__StateButton"
            mat-button
            type="button"
            (click)="toggleCreateAccount()"
            [innerHTML]="toggleCreateText"
          ></button>
        }

        @if (resettingPassword) {
          <mat-spinner></mat-spinner>
        } @else if (showPasswordResetConfirmation) {
          <p>Please check your email for a password reset link.</p>
        } @else {
          <button
            mat-raised-button
            color="primary"
            class="EmailLoginForm__LoginButton m-2 align-self-center"
            id="submit-button"
            type="submit"
            [innerHTML]="loginButtonText$ | async"
            [ngClass]="{
              'EmailLoginForm__LoginButton--Disabled': loginForm.invalid
            }"
            (click)="handleEmailLogin()"
          ></button>
        }
        @if (error) {
          <p class="warning" [innerHTML]="error"></p>
        }
      </form>
    </div>
  </div>
</div>
