import { UntypedFormGroup } from '@angular/forms';
import { Calculation } from '@smartflip/data-models';
import { roundNegativeToZero } from './calculation.helper';

export const calculateSellingCosts = (
  calcVals: Calculation,
  sellingForm: UntypedFormGroup,
): number => {
  const formVals = sellingForm.value;
  const newData = { comm: 0, 'trans-fees': 0, 'royalty-fees': 0 };
  const acquisition = calcVals.acq;
  const price = calcVals.summary['sell'].value;
  const franchise = formVals.franchiseTransactionFeesPercent;
  const franchisePercent = (franchise / 100) * price;
  const franchiseFees = acquisition['franchiseFees'].value;
  const royaltyPercent = (formVals['royalty-fees-percent'] / 100) * price;
  let total = 0;

  newData.comm = ((formVals.commpercent || 0) / 100) * price;
  newData['trans-fees'] = roundNegativeToZero(franchisePercent - franchiseFees);
  newData['royalty-fees'] = roundNegativeToZero(royaltyPercent - franchiseFees);

  sellingForm.patchValue(newData, { emitEvent: false });
  const rawFormVals = sellingForm.getRawValue();

  // Don't add percentages to the total
  for (const key of Object.keys(rawFormVals)) {
    if (key.indexOf('percent') === -1) {
      total += parseFloat(rawFormVals[key] || 0);
    }
  }

  return total;
};
