import { UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, debounceTime, pairwise, startWith, take } from 'rxjs';
import { LoadingService, RoiService } from '@smartflip/ui-utils';
import { propertyCategories } from '@smartflip/data-constants';
import {
  FieldConfig,
  SmartFlipUser,
  SwiperPhoto,
} from '@smartflip/data-models';
import {
  generalFields,
  ownerFields,
  mortgageFields,
} from '@smartflip/data-field-configuration';
import {
  CustomerService,
  PropertyService,
  UserService,
} from '@smartflip/helper-utils';
import { AlertDialogComponent } from '@smart-flip/shared-components';
import { confirmationDialogueData } from './general-information.constants';
import { PropertyWorkflowModalComponent } from './property-workflow-modal/property-workflow-modal.component';
import { PropertyDetailsService } from '../../property-details.service';
import { GeneralInformationService } from './general-information.service';

@Component({
  selector: 'app-general-details',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit {
  @Input() propertyID: string;

  private maxRefreshesAllowed: number = 1;
  canRefreshData: boolean = false;

  public objectKeys = Object.keys;
  generalForm: UntypedFormGroup;
  ownerForm: UntypedFormGroup;
  mortgageForm: UntypedFormGroup;
  public fieldList: FieldConfig[] = generalFields.filter(
    (field) => field.name !== 'purchasedate' && field.name !== 'solddate',
  );
  public ownerFieldList: FieldConfig[] = ownerFields;
  public mortgageFieldList: FieldConfig[] = mortgageFields;
  public imgUrl: string;
  public loading: boolean = true;
  public loanFeatureEnabled: boolean = false;
  public propertyStatus: string = '';
  public images: SwiperPhoto[] = [];
  public propertyStatusDate: string = '';
  public selectedPhotoIndex: number = 0;
  public showPurchaseInput: boolean = false;
  public workflowButtonText: string;

  constructor(
    private roi: RoiService,
    private propertyDetailsService: PropertyDetailsService,
    private propertyService: PropertyService,
    private generalService: GeneralInformationService,
    private dialog: MatDialog,
    private router: Router,
    private loadingService: LoadingService,
    private userService: UserService,
    private customerService: CustomerService,
  ) {}

  public onSubmit(e) {
    e.preventDefault();
  }

  public setPropertyStatusDate() {
    const { solddate, purchasedate } = this.roi.formGroups['gen']?.value || {};

    if (!solddate && !purchasedate) return;

    const newMessage =
      this.propertyStatus === 'Sold'
        ? `Sold: ${(solddate as Date).toLocaleDateString()}`
        : this.propertyStatus === 'Active'
          ? `Purchased: ${(purchasedate as Date).toLocaleDateString()}`
          : '';

    this.propertyStatusDate = newMessage;
  }

  public pullingAPIDataNotAllowed(): boolean {
    // TODO: SFP_142 Make this a paid feature
    return !!this.generalForm?.invalid;
  }

  public isDataRefreshAllowed(): boolean {
    return this.canRefreshData && this.generalForm?.valid;
  }

  public workflowProgress(editCurrentValues?: boolean) {
    // Assumes this cannot be clicked if property is already sold
    const isBuying: boolean = editCurrentValues
      ? this.propertyStatus === 'Active'
      : this.propertyStatus === 'potential';
    const { purchasedate, solddate } = this.roi.formGroups.gen.getRawValue();
    const { price, sell } = this.roi.formGroups.summary.getRawValue();
    const propertyWorkflowModal = this.dialog.open(
      PropertyWorkflowModalComponent,
      { data: { isBuying, purchasedate, solddate, price, sell } },
    );

    propertyWorkflowModal
      .afterClosed()
      .pipe(take(1))
      .subscribe(this.updatePropertyStage.bind(this));
  }

  // Update Property Data from API source
  public async refreshGeneralData() {
    if (!this.canRefreshData) return;

    const loader = await this.loadingService.showLoading(
      'Retrieving public data...',
    );

    this.generalService.refreshPropertyData(this.propertyID);
    loader.dismiss();

    this.userService.userRefreshedDataFor(this.propertyID);
  }

  public updateThumbnailImage(newImageUrl: string) {
    this.generalService.updateThumbnailImage(newImageUrl, this.propertyID);
  }

  public togglePurchasedDate() {
    this.showPurchaseInput = !this.showPurchaseInput;
  }

  public deleteProperty() {
    this.dialog
      .open(AlertDialogComponent, confirmationDialogueData)
      .afterClosed()
      .subscribe(this.completePropertyDeletion.bind(this));
  }

  /**
   * name
   */
  public async viewLoanApplication() {
    const { loan } =
      (await this.propertyService.getProperty(this.propertyID)) || {};

    if (loan) {
      this.router.navigate([`/loan-application/${this.propertyID}`]);
    } else {
      this.propertyService.startLoanApplication(this.propertyID);
    }
  }

  private completePropertyDeletion(shouldContinue: boolean) {
    if (shouldContinue) {
      this.propertyService.deleteProperty(this.propertyID);
      this.router.navigate(['/home']);
    }
  }

  private setStatus() {
    const { purchasedate, solddate } = this.roi.formGroups['gen'].value;
    const { active, potential, sold } = propertyCategories;

    this.workflowButtonText = solddate
      ? sold.buttonText
      : purchasedate
        ? active.buttonText
        : potential.buttonText;
    this.propertyStatus = solddate
      ? sold.label
      : purchasedate
        ? active.label
        : potential.label;
    this.setPropertyStatusDate();
  }

  private async initializeView() {
    const loader = await this.loadingService.showLoading(
      'Retrieving public data...',
    );

    this.setStatus();
    await this.setUpForms();
    this.loading = false;
    loader.dismiss();
  }

  private async setUpForms() {
    const propId = this.propertyID;
    const property = await this.propertyService.getProperty(propId);
    const { imgUrl, images } = property;

    this.imgUrl = imgUrl;
    this.images = images?.map((imgUrl, index) => ({
      url: imgUrl,
      alt: `Home Photo-${index}`,
    }));
    this.selectedPhotoIndex =
      this.images?.length > 0
        ? this.images.findIndex((photo) => photo.url === imgUrl)
        : this.selectedPhotoIndex;
    this.generalForm = this.roi.formGroups['gen'];
    this.ownerForm = this.roi.formGroups['owner'];
    this.mortgageForm = this.roi.formGroups['mortgage'];
    this.roi.formGroups['gen'].valueChanges // the generalForm updates too fast
      .pipe(startWith(null), debounceTime(800), pairwise())
      .subscribe(([prevValue, newValue]) => {
        this.propertyDetailsService.setProperty(
          this.roi.formGroups['gen'].valid,
        ); // assumes the only validation here is for the address

        if (
          prevValue?.solddate !== newValue?.solddate ||
          prevValue?.purchasedate !== newValue?.purchasedate
        ) {
          this.setStatus();
        }
      });
  }

  private updatePropertyStage(results: {
    newDate: Date;
    newDollarAmount: number;
    updatePurchaseDate: boolean;
  }) {
    if (results) {
      const { updatePurchaseDate, newDate, newDollarAmount } = results;
      const updateGenData = {
        [updatePurchaseDate ? 'purchasedate' : 'solddate']: newDate,
      };
      const updateSellData = {
        [updatePurchaseDate ? 'price' : 'sell']: newDollarAmount,
      };

      this.generalForm.patchValue(updateGenData);
      this.roi.formGroups['summary'].patchValue(updateSellData);
    }
  }

  ngOnInit() {
    this.initializeView();
    this.setUpForms();

    combineLatest([
      this.userService.loggedInUser$,
      this.customerService.hasActiveSubscription$,
    ]).subscribe(([user, hasActiveSubscription]: [SmartFlipUser, boolean]) => {
      const canRefreshData =
        hasActiveSubscription ||
        user.hasRefreshedDataFor?.[this.propertyID] < this.maxRefreshesAllowed;

      this.canRefreshData = canRefreshData;
    });
  }
}
