import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import {
  Auth,
  AuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  User,
  authState,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from '@angular/fire/auth';
import { FirebaseService } from '@smartflip/data-firebase';
import { NativeLoginService } from './native-login.service';
import { Providers } from 'libs/features/login/src/lib/login.constants';
import { createEmailUser, resetPassword } from './email-login.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public auth: Auth = inject(Auth);
  public authState$ = authState(this.auth);
  public createEmailUser = createEmailUser;
  public resetPassword = resetPassword;

  get authenticated(): boolean {
    return !!this.auth.currentUser;
  }

  handleSignedInWithDifferentProvider() {
    // Prompt the user.
  }

  // Sign in options
  async login(
    providerName: Providers,
    username?: string,
    password?: string,
  ): Promise<User | null> {
    if (username && password) {
      return this.emailLogin(username, password);
    }

    if (Capacitor.isNativePlatform()) {
      //Native login
      const { nativeGoogleLogin, nativeFacebookLogin, nativeAppleLogin } =
        this.nativeLogin;
      const nativeAuthLogins: Record<string, () => Promise<OAuthCredential>> = {
        google: nativeGoogleLogin,
        facebook: nativeFacebookLogin,
        apple: nativeAppleLogin,
      };
      const authToken = await nativeAuthLogins[providerName]();

      return this.nativeLogin.completeLogin(authToken);
    } else {
      // Web Login
      const webAuthProviders: Record<string, AuthProvider> = {
        google: new GoogleAuthProvider(),
        facebook: new FacebookAuthProvider(),
        apple: new OAuthProvider('apple.com'),
      };
      const provider = webAuthProviders[providerName];

      return this.socialSignIn(provider);
    }
  }

  // Shared login for web
  private async socialSignIn(provider: AuthProvider): Promise<User | null> {
    const auth = getAuth();
    const userCredential = await signInWithPopup(auth, provider);

    return userCredential?.user || null;
  }

  async emailLogin(email: string, password: string): Promise<User | null> {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );

    return userCredential?.user || null;
  }

  signOut(): void {
    this.router.navigate(['/login']);
    this.firebaseService.auth.signOut();
  }

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    public platform: Platform,
    private nativeLogin: NativeLoginService,
  ) {}
}
