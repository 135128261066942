import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DocumentData,
  Firestore,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  collection,
  getDoc,
} from '@angular/fire/firestore';
import { lastValueFrom, Observable } from 'rxjs';
import { take, timeout } from 'rxjs/operators';
import {
  AdditionalPropertyInfo,
  AddressQueryProps,
  Borrower,
  FormGroupFields,
  InitialGeneralData,
  Property,
  SmartFlipUser,
} from '@smartflip/data-models';
import { RoiService } from '@smartflip/ui-utils';
import { FirebaseService } from '@smartflip/data-firebase';
import { UserService } from './user.service';
import { VendorDataService } from './vendor-data.service';
import { setPropertyDefaults } from './property-service.helper';
import { Logging } from './logging.service';
import { LoanService } from './loan.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  propertyId!: string;
  $properties: Observable<DocumentData[]>;
  currentProperty!: Property;

  constructor(
    private router: Router,
    private firestore: Firestore,
    private fb: FirebaseService,
    private loanService: LoanService,
    private roi: RoiService,
    private user: UserService,
    private vendorData: VendorDataService,
    private logger: Logging,
  ) {
    this.$properties = this.fb.propCollection;
    this.roi.formsUpdated$.subscribe(this.updateProperty.bind(this));
  }

  async getProperty(propertyId: string): Promise<Property> {
    const property = doc(this.firestore, `Properties/${propertyId}`);
    const propertyDoc = await getDoc(property);

    this.logger.trace(`Property received, ${JSON.stringify(property)}`);

    return propertyDoc?.data() as Property;
  }

  async addProperty(property?: InitialGeneralData): Promise<string> {
    const userDefaults = await this.user.loggedInUser$
      .pipe(take(1))
      .toPromise();
    const userId = this.user.currentUserId;
    const standardProperty = new Property(userId);
    let defaultProperty = setPropertyDefaults(
      userDefaults as SmartFlipUser,
      standardProperty,
    );

    if (property) {
      const { images, ...general } = property;

      defaultProperty = {
        ...defaultProperty,
        gen: {
          ...defaultProperty.gen,
          ...general,
        },
        images: images || [],
        imgUrl: images?.[0] || '',
      };
    } else if (userDefaults && userDefaults.defaults) {
      defaultProperty = setPropertyDefaults(userDefaults, defaultProperty);
    }

    const propertiesCollection = collection(this.firestore, 'Properties');
    const propertyKey = await addDoc(propertiesCollection, defaultProperty);

    this.logger.trace(`Property added, ${JSON.stringify(defaultProperty)}`);

    return propertyKey?.id || '';
  }

  async deleteProperty(propertyId: string): Promise<void> {
    const traceMessage = `Property is getting deleted, ${JSON.stringify(
      propertyId,
    )}`;

    this.logger.trace(traceMessage);
    const propertyDocRef = doc(this.firestore, `Properties/${propertyId}`);

    return deleteDoc(propertyDocRef);
  }

  async setCurrentProperty(propertyId: string): Promise<void> {
    this.propertyId = propertyId;
    this.currentProperty = await this.getProperty(propertyId);
  }

  // Updates the CURRENT property
  async updateProperty(
    formSection: FormGroupFields,
    sectionData?: Record<string, unknown>,
    propertyId: string = this.propertyId,
  ): Promise<void> {
    if (!propertyId) {
      // TODO: Handle this more...snackbar?  navigate home?
      return;
    }

    const formGroup = this.roi.formGroups[formSection];
    const formData = { [formSection]: sectionData || formGroup?.getRawValue() };
    const propertyDoc = doc(this.firestore, `Properties/${propertyId}`);

    this.logger.trace(
      `Property ${propertyId} updated, ${JSON.stringify(formData)}`,
    );

    return updateDoc(propertyDoc, formData).then().catch();
  }

  /**
   *
   * @param addressString in the format ${address}, ${city}, ${state} ${zip}
   * @returns
   */
  async getDataFromVendor(
    address: AddressQueryProps,
  ): Promise<AdditionalPropertyInfo | null> {
    const additionalInfo = await this.vendorData.getPropertyByAddress(address);

    if (!additionalInfo) {
      return null;
    }
    const useDatafiniti = true;
    let attomId: number | undefined;

    if (!useDatafiniti) {
      attomId = additionalInfo?.attomId;
    }

    const ownerInfo = await this.vendorData.getOwnerInformation(attomId);
    const mortgageInfo = await this.vendorData.getMortgageInformation(attomId);
    const tmpOwner = ownerInfo as {
      owner: { first: string; last: string; address: string };
    };
    const name =
      `${tmpOwner?.owner?.first || ''} ${tmpOwner?.owner?.last || ''}` || '';

    return {
      name,
      ...additionalInfo,
      ...ownerInfo,
      ...mortgageInfo,
    } as AdditionalPropertyInfo;
  }

  async startLoanApplication(propertyId: string) {
    // TODO: check for comps and warn user they'll be missing from the loan
    const currentProperty = await this.getProperty(propertyId);
    const { borrowerInformation } = await lastValueFrom(
      this.user.loggedInUser$.pipe(timeout(2000))
    );
    const startingLoanData =( await this.loanService.createInitialLoanValues(
      currentProperty,
      borrowerInformation as Borrower
    )) as Record<string, unknown>;
    const sectionName: FormGroupFields = 'loan';
    
    // Comment out for testing
    this.updateProperty(sectionName, startingLoanData, propertyId);
    this.router.navigate(['/home/loan-application', propertyId]);
  }
}
