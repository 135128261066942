import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  UserCredential,
} from '@angular/fire/auth';

export const createEmailUser = (
  email: string,
  password: string,
): Promise<UserCredential> => {
  const auth = getAuth();

  return createUserWithEmailAndPassword(auth, email, password);
};

export const resetPassword = (email: string): Promise<void> => {
  const auth = getAuth();

  return sendPasswordResetEmail(auth, email);
};
