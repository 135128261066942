<main id="comparables-view" data-test-id="comparablesView" class="Comparables">
  @if (propertyIsSet) {
    <div class="Comparables__MapToggleRow container-fluid d-flex">
      <div
        class="p-2 col-6 d-flex justify-content-start align-items-center"
        *ngIf="showMapToggle"
      >
        <mat-checkbox
          (change)="toggleMap()"
          [checked]="showMap"
          aria-label="Show Map Checkbox"
        ></mat-checkbox>
        <label class="Comparables__MapToggleLabel">&nbsp;&nbsp;Show Map</label>
      </div>

      <div
        class="p-2 col-6 d-flex justify-content-end align-items-center"
        [ngClass]="isNative ? 'col-12' : 'col-6'"
      >
        <button
          aria-label="Filter button"
          color="primary"
          class="Comparables__Button"
          data-test-id="comparablesFilterButton"
          id="comps-filter-button"
          mat-button
          (click)="showFilter()"
        >
          <span
            class="Comparables__FilterLabel"
            [innerHTML]="'Filter Results'"
          ></span>
          <mat-icon>tune</mat-icon>
        </button>
      </div>
    </div>

    <section
      class="Comparables__MapAndTableContainer"
      [ngClass]="{ 'Comparables__MapAndTableContainer--NoMap': !showMap }"
    >
      <div class="Comparables__ListSection">
        @if (!error) {
          <mat-card *ngIf="!error">
            <smartflip-comparables-filter-actions
              [pullCompsButtonText]="getCompsButtonText"
              [selectedComps]="selectedComps"
              [arvButtonText]="calculateARVButtonText"
              [filterOptions]="currentFilters"
              (pullCompsClicked)="pullComps($event)"
            ></smartflip-comparables-filter-actions>

            <ng-container *ngIf="loadingComps$ | async; else loaded">
              <div class="Comparables__SpinnerWrapper">
                <mat-spinner class="Comparables__Spinner"></mat-spinner>
              </div>
            </ng-container>

            <ng-template #loaded>
              @if (compsList$ | async; as compsList) {
                <ng-container *ngIf="compsList.length > 0; else searchError">
                  <div
                    class="container-fluid Comparables__List"
                    data-test-id="comparablesList"
                    id="comparables-list"
                  >
                    <app-comparables-table
                      class="Comparables__CompsTable"
                      [compsSource]="compsList"
                      [highlightedAddress]="highlightedAddress$ | async"
                      (selectionChange)="updateCompsSelected($event)"
                      (hoverChange)="onHoverChange($event)"
                    ></app-comparables-table>
                  </div>
                </ng-container>
              }
            </ng-template>
          </mat-card>
        } @else {
          <p class="warning" [innerHTML]="errorText"></p>
        }
      </div>

      @if (showMap) {
        <div
          class="Comparables__MapSection"
          data-test-id="comparablesMapSection"
          id="comparables-map"
        >
          @if (this.comparablesMarkers) {
            <lib-interactive-map
              [lat]="location[0]"
              [long]="location[1]"
              [locations]="comparablesMarkers"
              [startingLocationLabel]="currentPropertyLabel"
              (markerSelected)="highlightPropertyInList($event)"
            ></lib-interactive-map>
          }
        </div>
      }
    </section>
  } @else {
    <p class="warning" [innerHTML]="enterAddressWarning"></p>
  }

  <!-- NO DATA -->
  <ng-template #searchError>
    <p class="warning" [innerHTML]="comparablesWarningMessage"></p>
  </ng-template>
  <ng-template #notSearched>
    <div class="container-fluid">
      <p [innerHTML]="comparablesInstructions"></p>
    </div>
  </ng-template>
</main>
