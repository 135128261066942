import { Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, take } from 'rxjs';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  getDoc,
} from '@angular/fire/firestore';
import { ADMIN_USER, ADMIN_USER_DEV } from '@smartflip/data-constants';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private firestore: Firestore = inject(Firestore);
  private currentCustomer: Subject<Record<string, unknown>> =
    new BehaviorSubject({});
  public currentCustomer$ = this.currentCustomer.asObservable();
  private hasActiveSubscription: Subject<boolean> = new BehaviorSubject(false);
  public hasActiveSubscription$ = this.hasActiveSubscription.asObservable();
  private userId: string = '';

  constructor(
    @Inject('environment')
    private environment: any,
  ) {}

  public async getCustomerByUserId(
    userId: string,
  ): Promise<Record<string, unknown>> {
    const promise: Promise<Record<string, unknown>> = new Promise((resolve) => {
      const path = `customers/${userId}`;
      const customerRef = doc(this.firestore, path);

      this.userId = userId;

      combineLatest([
        docData(customerRef),
        collectionData(collection(this.firestore, path, 'subscriptions')),
      ])
        .pipe(take(1))
        .subscribe(([customer, subscriptions]: [any, any[]]) => {
          const customerWithSubscriptions = {
            ...customer,
            subscriptions,
          };
          // console.log('customerWithSubscriptions', customerWithSubscriptions);
          this.populateSubscriptions(customerWithSubscriptions);
          this.setActiveSubscriptionStatus(customerWithSubscriptions);

          resolve(
            customerWithSubscriptions as unknown as Record<string, unknown>,
          );
        });
    });

    return promise;
  }

  private populateSubscriptions(customer: any) {
    const { subscriptions } = customer;

    if (subscriptions.length === 0) {
      this.currentCustomer.next(customer);
    } else {
      getDoc(subscriptions[0].product)
        .then((product: any) => {
          this.currentCustomer.next({
            ...customer,
            subscriptions: (subscriptions as any[]).map((subscription) => ({
              ...subscription,
              product: product.data(),
            })),
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  private setActiveSubscriptionStatus(customer: any) {
    let hasSubscription = false;

    if (
      this.environment.free ||
      this.userId === ADMIN_USER ||
      this.userId === ADMIN_USER_DEV ||
      Capacitor.isNativePlatform() // TODO: remove once Apple in-app purchases are enabled
    ) {
      hasSubscription = true;
    } else if (customer?.subscriptions?.['length'] > 0) {
      hasSubscription = (
        customer?.subscriptions as Record<string, unknown>[]
      ).reduce((status, sub) => sub['status'] === 'active' || status, false);
    }

    this.hasActiveSubscription.next(hasSubscription);
  }
}
