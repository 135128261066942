export const DEFAULT_IMAGE_URL = '/assets/img/default-house.svg';

export const PROFILE_DEFAULTS = {
  duration: 6,
  loanpoints: 0.02,
  loanrate: 12,
  appraisalFee: 600,
  state: 'NJ',
  franchiseFees: 0,
  franchiseTransactionFeesPercent: 0,
  commpercent: 0,
  inspectionFees: 0,
  attorneyFeesSeller: 0,
};

export const emptyAdditionalPropertyInfo = {
  year: '',
  sqft: '',
  lot: '',
  attomId: 0,
};

export const specialSections = ['gen', 'mortgage', 'owner'];

export const SQUARE_FEET_PER_ACRE = 43560;

export const ADMIN_USER = 'fgFw271h3mXWnxKlyJfj6mUuKbk2';

export const ADMIN_USER_DEV = '3dpsGalOihfRN3t3U32xBAOvFgY2';

export const PRIVACY_POLICY_URL = 'https://thesmartflip.com/privacy';
